import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import SEO from "../../components/Layout/SEO";
import SuccessStories from "../../sections/camelo/SuccessStories";

import bgSection from "../../assets/image/customers/bdash-logistics-scheduling-story-featured-image.png";
import timeOffRequest from "../../assets/image/scheduling-software/request-time-off-and-manage-time-off-requests.png"

const MiddleCTA = () => {
  return (
    <Row className="bg-default-1 border-top">
      <Container>
        <Col xs="12" className="text-center pb-24">
          <h2 className="gr-text-5 pt-24 pb-8">
            <span
              className="highlighted1"
              style={{ backgroundPosition: "0 20px" }}
            >
              Try Camelo now and see the difference yourself.
            </span>
          </h2>
          <div>
            <Link
              onClick={() => {
                if (typeof window.gtag_report_conversion === "function") {
                  window.gtag_report_conversion(
                    process.env.SIGNUP_URL,
                  );
                }
              }}
              to={process.env.SIGNUP_URL}
              target="__blank"
              className="btn btn-primary with-icon gr-hover-y"
            >
              Start scheduling free
              <i className="icon icon-tail-right font-weight-bold"></i>
            </Link>
            <p className="gr-text-12 pt-8">
              59 companies signed up in the last week alone!
            </p>
          </div>
        </Col>
      </Container>
    </Row>
  );
};

const NailStory = () => {
  return (
    <>
      <PageWrapper>
        <SEO
          title="Customer story: How BDash Logistics uses Camelo to transform courier scheduling"
          description="Find out how the Camelo employee scheduling app transforms shift scheduling, timesheets, & communication for BDash Logistics, an express courier service provider specializing in medical deliveries across the US."
        ></SEO>
        <div className="inner-banner bg-default-6 pt-24 pt-lg-30 pb-lg-15">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9">
                <div className="px-md-12 mb-13 text-center">
                  <h1 className="title gr-text-4 mb-8 mb-lg-12">
                    How BDash Logistics uses Camelo to transform courier scheduling
                  </h1>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="video-section py-28 py-sm-20 py-lg-35 dark-mode-texts "
          css={`
            background-image: url(${bgSection});
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          `}
        />

        <div className="pb-2 pb-lg-9 pt-13 pt-lg-24 bg-default-6 customer-story">
          <Container>
            <Row className="justify-content-center">
              <Col xl="10" lg="10">
                <div className="px-md-12 text-black mb-13 mb-lg-22">
                  <p className="gr-text-8 text-black">
                    <Link to="https://www.bdashlogistics.com/" target="__blank">
                      {`BDash Logistics`}
                    </Link>
                    {' '} is an express courier service provider specializing in medical deliveries
                    across the US. Founded in 2021 by Barry Dunn—an industry veteran with over
                    20 years of experience—BDash emerged from a desire to make the changes
                    in the courier industry that many fellows want to see.
                  </p>
                  <p className="gr-text-8 text-black">
                    Like many growing logistics operations, BDash Logistics soon found themselves drowning
                    in scheduling problems. Even when they tried many different ways, like using spreadsheets
                    or notepads, the management was not as efficient as they expected. The turning point
                    came when they found Camelo and realized it was exactly
                    what they were looking for.
                  </p>

                  <h4 className="pt-8 pb-4">
                    From driver to business owner: a vision for change
                  </h4>
                  <p className="gr-text-8 text-black">
                    BDash's journey began when Barry thought of taking the leap to make something different.
                    With over 20 years as a contracted driver for various courier companies,
                    Barry decided to use his extensive experience to create the change he and his colleagues
                    wanted to see in the industry.
                  </p>
                  <p className="gr-text-8 text-black">
                    “I started off as a driver in 2005. I've been contracted with various companies, learning
                    the ins and outs of the industry,” the owner said. “In April 2021, I decided to start
                    my own company,” he added.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “My purpose was to be the change that I and many of my colleagues wanted to see
                      in the industry.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">
                    Dealing with scheduling headaches
                  </h4>
                  <p className="gr-text-8 text-black">
                    Before using Camelo, it was difficult for BDash Logistics to keep track of
                    their drivers' vacation schedules, especially with their large number of staff.
                    Knowing which drivers were assigned to specific routes was also a pain for them.
                  </p>
                  <p className="gr-text-8 text-black">
                    The most frustrating problem was the lack of a central system where drivers
                    could see schedules and claim open shifts automatically. Without intervention
                    from managers, the process wouldn’t have gone smoothly, and that took Barry a lot of time
                    and energy.
                  </p>
                  <p className="gr-text-8 text-black">
                    Their initial solutions were familiar ones—Excel spreadsheets and shared notepads.
                    But as the company expanded, these tools quickly showed their limitations.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Our original solutions of recording everything in Excel spreadsheets and
                      shared notepads weren't as efficient as we needed. Making constant changes to
                      my Excel spreadsheets was very tedious and time-consuming.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    Communication difficulties with drivers made it even more complicated for
                    their daily operations. Forgotten updates about shift changes, drivers mistakenly
                    scheduled for the wrong shifts, and miscalculating work hours became too common,
                    creating frustration for everyone.
                  </p>

                  <h4 className="pt-8 pb-4">The turning point: discovering Camelo</h4>
                  <p className="gr-text-8 text-black">
                    The search for a better solution led Barry to discover Camelo.
                    After taking time to try the app, he found its interface welcoming and the features
                    remarkably practical.
                  </p>
                  <p className="gr-text-8 text-black">
                    “It was pretty easy to get started,” he shared. He also told us he spent some time
                    getting more familiar with Camelo. "Of course, with any new software, there is
                    some type of learning curve," he explained.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “I feel like a lot of the features in Camelo are very useful, especially after
                      taking out the time to actually learn them.”
                    </p>
                  </blockquote>

                  <h4 className="pt-8 pb-4">A new scheduling era begins</h4>
                  <p className="gr-text-8 text-black">
                    With Camelo, BDash Logistics transforms their scheduling process from a daily headache
                    into a smooth process. What once required hours of tedious spreadsheet editing
                    now takes just a few clicks and taps.
                  </p>
                  <blockquote className="blockquote mb-4 card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “With Camelo, I'm able to post the schedules in a few steps.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    The ability for drivers to submit time-off requests directly through the app has been
                    a game-changer for the team. "It has been extremely useful for the drivers to be able
                    to submit any time-off requests within the app, without management having to
                    try to remember who needed off and when," Barry said.
                  </p>
                  <img
                    className="w-100"
                    src={timeOffRequest}
                    alt="Request time off and manage time off requests on Camelo"
                  />
                  <p className="text-center font-italic">
                    Send time off requests and manage time off requests on Camelo
                  </p>
                  <p className="gr-text-8 text-black">
                    This simple feature eases the burden on team management and reduces
                    the risk of scheduling conflicts, creating a more reliable system that benefits
                    both the company and its drivers.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Favorite features that drive success
                  </h4>
                  <p className="gr-text-8 text-black">
                    BDash Logistics quickly embraced several of Camelo's key features that addressed
                    their specific scheduling problems. {' '}
                    <Link
                      to="https://camelohq.com/features/scheduling-software/"
                      target="__blank"
                    >
                      {`The scheduling tools`}
                    </Link> become the backbone of their daily operations, while shift swaps and
                    open shifts give their drivers flexibility and autonomy.
                  </p>
                  <p className="gr-text-8 text-black">
                    The chat feature improves {' '}
                    <Link
                      to="https://camelohq.com/features/workplace-communication/"
                      target="__blank"
                    >
                      {`team communication`}
                    </Link> and avoids misunderstandings that once interrupted their operations.
                    Time-off management brings order to the scheduling process that used to be chaotic
                    with messy spreadsheets and notes.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “I feel like all of these features are equally important and very valuable.”
                    </p>
                  </blockquote>
                  <h4 className="pt-8 pb-4">
                    The perfect match for a growing business
                  </h4>
                  <p className="gr-text-8 text-black">
                    For BDash Logistics, Camelo isn't just another tool—it's the scheduling solution
                    they've been searching for over many years.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “Truthfully, Camelo is exactly what I have been looking for in a scheduling software
                      for years.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    The app has brought significant improvements to BDash Logistics, allowing them to
                    focus on expanding their business rather than getting slowed down in administrative tasks.
                  </p>
                  <p className="gr-text-8 text-black">
                    When asked if they would recommend Camelo to others, Barry’s response
                    leaves no room for doubt. "Most definitely! It has been a game-changer for me
                    and my business," he excitedly shared.
                  </p>

                  <h4 className="pt-8 pb-4">
                    Looking to the future: promising partnership
                  </h4>
                  <p className="gr-text-8 text-black">
                    As BDash Logistics continues to grow, they're eager to find ways to
                    further integrate Camelo into their operations. Barry shares his plans to
                    implement it into some of his software that is soon to be developed.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “It would be nice to actually have some type of partnership with Camelo,
                      where I can integrate it into some software that I plan on getting developed
                      in the near future.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    The founder has plans to build a platform for courier companies and sees Camelo
                    as a potential partner in this venture. “My plan is to create an all-in-one platform
                    for courier companies, allowing them to run their businesses completely off of
                    software that provides all the features needed to do so.”
                  </p>
                  <p className="gr-text-8 text-black">
                    Barry also expresses his willingness to keep growing with Camelo by giving us
                    his valuable contributions.
                  </p>
                  <blockquote className="blockquote card-body">
                    <p className="text-black pt-4 gr-text-6 lead">
                      “I am looking forward to growing with the company (Camelo), providing some
                      feature suggestions along the way.”
                    </p>
                  </blockquote>
                  <p className="gr-text-8 text-black">
                    For BDash Logistics, Camelo isn't just solving scheduling challenges—it's a partner
                    for the company’s future journey. Now with the ease of scheduling, BDash Logistics
                    can focus on providing exceptional courier services to their clients, particularly
                    in the crucial medical field.
                  </p>
                  <p className="gr-text-8 text-black">
                    Discover how to create automatic scheduling for transportation workers
                    like BDash Logistics {' '}
                    <Link
                      to="https://camelohq.com/industries/transportation/"
                      target="__blank"
                    >
                      {`here`}
                    </Link>!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <MiddleCTA />
        <SuccessStories />
      </PageWrapper>
    </>
  );
};
export default NailStory;
